
import "bootstrap/dist/css/bootstrap.min.css";
import { defineComponent } from 'vue';
import GlobalHeader from "@/components/GlobalHeader.vue";
import GlobalFooter from "@/components/GlobalFooter.vue";

export default defineComponent({
  name: 'App',
  components: {GlobalFooter, GlobalHeader},
  setup() {

    return {}
  },
});
