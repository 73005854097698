
import { defineComponent } from 'vue';

export default defineComponent({
  name: "GlobalFooter",
  components: {},
  setup() {
    return {}
  }
})
