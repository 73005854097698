import { createApp } from 'vue'
import { createRouter, createWebHistory } from "vue-router";

import App from './App.vue'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      component: () => import("./views/Index.vue")
    },
    {
      path: "/:pathMatch(.*)*", // 404
      component: () => import("./views/Index.vue")
    }
  ]
})

createApp(App)
.use(router)
.mount("#app")
